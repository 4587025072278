import React from "react";
import { useNavigate } from "react-router-dom";

const FlavoursElement = (props) => {
  const router = useNavigate();
  function color_rating() {
    if (props.flavour.Rating >= 0 && props.flavour.Rating < 4.1) {
      return (
        <span className="fluid-element-rat fluid-element-text fluid-element-color-red">
          {props.flavour.Rating}
        </span>
      );
    } else if (props.flavour.Rating > 2 && props.flavour.Rating < 6.1) {
      return (
        <span className="fluid-element-rat fluid-element-text fluid-element-color-orange">
          {props.flavour.Rating}
        </span>
      );
    } else if (props.flavour.Rating > 4 && props.flavour.Rating < 7.1) {
      return (
        <span className="fluid-element-rat fluid-element-text fluid-element-color-yellow">
          {props.flavour.Rating}
        </span>
      );
    } else if (props.flavour.Rating > 6 && props.flavour.Rating < 8.5) {
      return (
        <span className="fluid-element-rat fluid-element-text fluid-element-color-lightgreen">
          {props.flavour.Rating}
        </span>
      );
    } else {
      return (
        <span className="fluid-element-rat fluid-element-text fluid-element-color-green">
          {props.flavour.Rating}
        </span>
      );
    }
  }
  return (
    <div
      className="flavour-element-panel"
      onClick={() =>
        router(
          `/flavours/${props.flavour.id}`,
          props.setVisible("flex"),
          document.body.classList.add("modal-open")
        )
      }
    >
      <div className="fluid-element-number-plate fluid-element-state-plate">
        <span className="fluid-element-number fluid-element-text">
          {props.num}
        </span>
      </div>
      <div className="flavour-element-name-plate fluid-element-state-plate">
        <span className="fluid-element-name fluid-element-text">
          {props.flavour.Name}
        </span>
        <span className="fluid-element-tnvp fluid-element-text">
          {props.flavour.Taste}
        </span>
      </div>
      <div className="flavour-element-img-plate fluid-element-state-plate">
        <img
          className="flavour-element-img"
          src={
            "https://api.smoke-story.site/images/flavours/" +
            props.flavour.Image
          }
          alt={props.flavour.Name}
        ></img>
      </div>
      <div className="fluid-element-dsc-plate fluid-element-state-plate">
        <span className="fluid-element-dsc fluid-element-text">
          {props.flavour.Description}
        </span>
      </div>
      <div className="flavour-element-rat-plate fluid-element-state-plate">
        {color_rating()}
      </div>
    </div>
  );
};

export default FlavoursElement;
