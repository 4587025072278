import React from "react";
import styled, { keyframes } from "styled-components";

const BuyingElement = (props) => {
  const pulse = keyframes` from {width: 0;}`;
  const ColorPlate = styled.div`
    animation: ${pulse} 2.5s ease-in-out;
  `;
  return (
    <div className="byuing-element-plate">
      <ColorPlate
        className="byuing-element-color-plate"
        style={{
          background: props.buying.color + "a7",
          width: (props.buying.count * 100) / props.maxCount + "%",
        }}
      >
        <span className="byuing-element-number">{props.num}</span>
        <img
          className="byuing-element-img"
          src={
            "https://api.smoke-story.site/images/manufacturers/" +
            props.buying.image
          }
          alt={props.buying.name}
        ></img>
        <span className="byuing-element-name">{props.buying.name}</span>
        <div className="byuing-element-zone">
          <span className="byuing-element-color">{props.buying.count}</span>
        </div>
      </ColorPlate>
    </div>
  );
};

export default BuyingElement;
