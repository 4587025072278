import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UName from "../UI/UName";
import UInputName from "../UI/UInputName";
import UInput from "../UI/UInput";
import UArea from "../UI/UArea";
import UButton from "../UI/UButton";
import UImage from "../UI/UImage";
import ApiService from "../../API/ApiService";

const FlavoursModal = ({ visible, setVisible, updateFlavour }) => {
  //, removeFlavour, addFlavour, patchFlavour}) => {
  const router = useNavigate();
  const params = useParams();
  const [isNewFlavour, setIsNewFlavour] = useState(true);

  const [newFlavour, setNewFlavour] = useState({
    Name: "",
    Taste: "",
    Rating: "",
    Description: "",
    Image: "",
  });
  const [inputCode, setInputCode] = useState("");
  const [inputImage, setInputImage] = useState("");

  const fetching = async () => {
    if (params.id && params.id === "new") {
      setIsNewFlavour(true);
    } else if (params.id) {
      const response = await ApiService.getFlavoursId(params.id);
      setNewFlavour({
        Name: response.data.Name,
        Taste: response.data.Taste,
        Rating: response.data.Rating,
        Description: response.data.Description,
        Image:
          "https://api.smoke-story.site/images/flavours/" + response.data.Image,
      });
      setIsNewFlavour(false);
      setInputImage("");
    }
  };

  const deleteTab = async (e) => {
    e.preventDefault();
    if (!inputCode) {
      alert("Укажите код для работы запроса");
    } else {
      const response = await ApiService.deleteFlavours(params.id, inputCode);
      if (!response) {
        alert(
          "Произошла ошибка при выполнении запроса, проверьте правильность кода"
        );
      } else {
        router(`/flavours`);
        setVisible("none");
        //removeFlavour(params.id);
        updateFlavour();
      }
    }
  };

  const workTab = async (e) => {
    e.preventDefault();
    if (
      newFlavour.Name &&
      newFlavour.Taste &&
      Number.isInteger(Number(newFlavour.Rating) * 10) &&
      inputCode &&
      newFlavour.Description
    ) {
      if (isNewFlavour) {
        if (inputImage) {
          const bodyFormData = new FormData();
          bodyFormData.append("id", "");
          bodyFormData.append("Name", newFlavour.Name);
          bodyFormData.append("Taste", newFlavour.Taste);
          bodyFormData.append("Rating", Number(newFlavour.Rating));
          bodyFormData.append("Description", newFlavour.Description);
          bodyFormData.append("Image", inputImage);
          const response = await ApiService.postFlavours(
            bodyFormData,
            inputCode
          );
          if (!response) {
            alert(
              "Произошла ошибка при выполнении запроса, проверьте правильность кода"
            );
          } else {
            router(`/flavours`);
            setVisible("none");
            /*const finallyFlavour ={...newFlavour, id: Number(response.data.record_id)};
                        addFlavour(finallyFlavour);*/
            updateFlavour();
          }
        } else {
          alert("Добавьте изображение");
        }
      } else {
        const PatchBody = {
          Name: newFlavour.Name,
          Taste: newFlavour.Taste,
          Rating: Number(newFlavour.Rating),
          Description: newFlavour.Description,
          Image: inputImage,
        };
        const response = await ApiService.patchFlavours(
          PatchBody,
          params.id,
          inputCode
        );
        if (!response) {
          alert(
            "Произошла ошибка при выполнении запроса, проверьте правильность кода"
          );
        } else {
          router(`/flavours`);
          setVisible("none");
          //patchFlavour();
          updateFlavour();
        }
      }
    } else {
      alert("Заполните все данные или проверьте правильность их заполнения");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = function () {
      setNewFlavour({ ...newFlavour, Image: reader.result });
      setInputImage(reader.result);
    };
  };

  useEffect(() => {
    setNewFlavour({
      Name: "",
      Taste: "",
      Rating: "",
      Description: "",
      Image: "",
    });
    fetching();
  }, [visible]);

  return (
    <div
      className="modalFog"
      style={{ display: visible }}
      onClick={() =>
        router(
          `/flavours`,
          setVisible("none"),
          document.body.classList.remove("modal-open")
        )
      }
    >
      <div className="modal-plate" onClick={(e) => e.stopPropagation()}>
        <UName name="Ароматизатор" />
        <form>
          <UInputName
            placeholder="Имя"
            type="text"
            value={newFlavour.Name}
            onChange={(e) =>
              setNewFlavour({ ...newFlavour, Name: e.target.value })
            }
          />
          <div className="ui-flex-flavours">
            <div className="ui-flex-left-image">
              <UInput
                placeholder="Вкус"
                type="text"
                value={newFlavour.Taste}
                onChange={(e) =>
                  setNewFlavour({ ...newFlavour, Taste: e.target.value })
                }
              />
              <UInput
                placeholder="Рейтинг"
                type="text"
                value={newFlavour.Rating}
                onChange={(e) =>
                  setNewFlavour({ ...newFlavour, Rating: e.target.value })
                }
              />
              <UInput
                placeholder="Код"
                type="password"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
              />
            </div>
            <UImage
              value={newFlavour.Image}
              onChange={handleChange}
              alt={newFlavour.Name}
            />
          </div>
          <UArea
            placeholder="Описание"
            type="text"
            value={newFlavour.Description}
            onChange={(e) =>
              setNewFlavour({ ...newFlavour, Description: e.target.value })
            }
          />
          <div className="ui-button-plate">
            {isNewFlavour ? (
              <span></span>
            ) : (
              <UButton onClick={deleteTab}>Удалить</UButton>
            )}
            <UButton onClick={workTab}>Сохранить</UButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FlavoursModal;
