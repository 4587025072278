import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UName from "../UI/UName";
import UInputName from "../UI/UInputName";
import UInput from "../UI/UInput";
import UArea from "../UI/UArea";
import UButton from "../UI/UButton";
import UImage from "../UI/UImage";
import ApiService from "../../API/ApiService";
import UToggle from "../UI/UToggle";
import USelect from "../UI/USelect";

const FluidsModal = ({ visible, setVisible, updateFluid }) => {
  const router = useNavigate();
  const params = useParams();
  const [isNewFluid, setIsNewFluid] = useState(true);

  const [newFluid, setNewFluid] = useState({
    Name: "",
    Taste: "",
    Nicotine: "",
    Type_of_nicotine: "",
    Volume: "",
    Price: "",
    Manufacturer: "",
    Description: "",
    Rating: "",
    Buying: "",
    Image: "",
  });
  const [inputCode, setInputCode] = useState("");
  const [inputImage, setInputImage] = useState("");
  const [typeNic, setTypeNic] = useState(false);
  const [nic, setNic] = useState(false);

  const [manufacturers, setManufacturers] = useState([]);

  const fethingManufacurers = async () => {
    const response = await ApiService.getManufacturersFull();
    setManufacturers(response.data);
  };

  useEffect(() => {
    fethingManufacurers();
  }, []);

  const fetching = async () => {
    if (params.id && params.id === "new") {
      setIsNewFluid(true);
    } else if (params.id) {
      const response = await ApiService.getFluidsId(params.id);
      {
        response.data.Type_of_nicotine === "2"
          ? setTypeNic(true)
          : setTypeNic(false);
      }
      setNewFluid({
        Name: response.data.Name,
        Taste: response.data.Taste,
        Nicotine: response.data.Nicotine,
        Type_of_nicotine: response.data.Type_of_nicotine,
        Volume: response.data.Volume,
        Price: response.data.Price,
        Manufacturer: response.data.Manufacturer,
        Description: response.data.Description,
        Rating: response.data.Rating,
        Buying: response.data.Buying,
        Image:
          "https://api.smoke-story.site/images/fluids/" + response.data.Image,
      });
      setIsNewFluid(false);
      setInputImage("");
    }
  };

  const deleteTab = async (e) => {
    e.preventDefault();
    if (!inputCode) {
      alert("Укажите код для работы запроса");
    } else {
      const response = await ApiService.deleteFluids(params.id, inputCode);
      if (!response) {
        alert(
          "Произошла ошибка при выполнении запроса, проверьте правильность кода"
        );
      } else {
        router(`/fluids`);
        setVisible("none");
        updateFluid();
      }
    }
  };

  const workTab = async (e) => {
    e.preventDefault();
    if (
      newFluid.Name &&
      newFluid.Taste &&
      Number.isInteger(Number(newFluid.Nicotine) * 10) &&
      Number.isInteger(Number(newFluid.Volume)) &&
      newFluid.Price &&
      newFluid.Manufacturer !== "none" &&
      inputCode &&
      newFluid.Description &&
      Number.isInteger(Number(newFluid.Rating)) &&
      newFluid.Buying !== "none"
    ) {
      if (isNewFluid) {
        const bodyFormData = new FormData();
        bodyFormData.append("id", "");
        bodyFormData.append("Name", newFluid.Name);
        bodyFormData.append("Taste", newFluid.Taste);
        bodyFormData.append("Nicotine", Number(newFluid.Nicotine));
        if (newFluid.Nicotine === "0") {
          bodyFormData.append("Type_of_nicotine", 3);
        } else {
          {
            typeNic
              ? bodyFormData.append("Type_of_nicotine", 2)
              : bodyFormData.append("Type_of_nicotine", 1);
          }
        }
        bodyFormData.append("Volume", Number(newFluid.Volume));
        bodyFormData.append("Price", newFluid.Price);
        bodyFormData.append("Manufacturer", Number(newFluid.Manufacturer));
        bodyFormData.append("Description", newFluid.Description);
        bodyFormData.append("Rating", Number(newFluid.Rating));
        bodyFormData.append("Buying", Number(newFluid.Buying));
        bodyFormData.append("Image", inputImage);
        const response = await ApiService.postFluids(bodyFormData, inputCode);
        if (!response) {
          alert(
            "Произошла ошибка при выполнении запроса, проверьте правильность кода"
          );
        } else {
          router(`/fluids`);
          setVisible("none");
          updateFluid();
        }
      } else {
        if (newFluid.Nicotine === "0" || newFluid.Nicotine === 0) {
          setNic(3);
        } else {
          {
            typeNic ? setNic(2) : setNic(1);
          }
        }
        const PatchBody = {
          Name: newFluid.Name,
          Taste: newFluid.Taste,
          Nicotine: Number(newFluid.Nicotine),
          Type_of_nicotine: Number(nic),
          Volume: Number(newFluid.Volume),
          Price: newFluid.Price,
          Manufacturer: Number(newFluid.Manufacturer),
          Description: newFluid.Description,
          Rating: Number(newFluid.Rating),
          Buying: Number(newFluid.Buying),
          Image: inputImage,
        };

        const response = await ApiService.patchFluids(
          PatchBody,
          params.id,
          inputCode
        );
        if (!response) {
          alert(
            "Произошла ошибка при выполнении запроса, проверьте правильность кода"
          );
        } else {
          router(`/fluids`);
          setVisible("none");
          updateFluid();
        }
      }
    } else {
      alert("Заполните все данные или проверьте правильность их заполнения");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = function () {
      setNewFluid({ ...newFluid, Image: reader.result });
      setInputImage(reader.result);
    };
  };

  useEffect(() => {
    setNewFluid({
      Name: "",
      Taste: "",
      Nicotine: "",
      Type_of_nicotine: "",
      Volume: "",
      Price: "",
      Manufacturer: "none",
      Description: "",
      Rating: "",
      Buying: "none",
      Image: "",
    });
    fetching();
  }, [visible]);

  return (
    <div
      className="modalFog"
      style={{ display: visible }}
      onClick={() =>
        router(
          `/fluids`,
          setVisible("none"),
          document.body.classList.remove("modal-open")
        )
      }
    >
      <div className="modal-plate" onClick={(e) => e.stopPropagation()}>
        <UName name="Жидкость" />
        <form>
          <UInputName
            placeholder="Имя"
            type="text"
            value={newFluid.Name}
            onChange={(e) => setNewFluid({ ...newFluid, Name: e.target.value })}
          />
          <div className="ui-flex-flavours">
            <div className="ui-flex-left-image">
              <UInput
                placeholder="Вкус"
                type="text"
                value={newFluid.Taste}
                onChange={(e) =>
                  setNewFluid({ ...newFluid, Taste: e.target.value })
                }
              />
              <div className="ui-input-fluid-line">
                <UInput
                  placeholder="Никотин"
                  type="text"
                  style={{ marginRight: "22px" }}
                  value={newFluid.Nicotine}
                  onChange={(e) =>
                    setNewFluid({ ...newFluid, Nicotine: e.target.value })
                  }
                />
                <UToggle
                  style={{ width: "65px" }}
                  checked={typeNic}
                  onChange={(e) => setTypeNic(!typeNic)}
                />
              </div>
              <div className="ui-input-fluid-line">
                <UInput
                  placeholder="Объём"
                  type="text"
                  style={{ marginRight: "22px" }}
                  value={newFluid.Volume}
                  onChange={(e) =>
                    setNewFluid({ ...newFluid, Volume: e.target.value })
                  }
                />
                <UInput
                  placeholder="Код"
                  type="password"
                  value={inputCode}
                  onChange={(e) => setInputCode(e.target.value)}
                />
              </div>
            </div>
            <UImage
              value={newFluid.Image}
              onChange={handleChange}
              alt={newFluid.Name}
            />
          </div>
          <USelect
            className="select-wrapper1"
            value={newFluid.Manufacturer}
            onChange={(e) =>
              setNewFluid({ ...newFluid, Manufacturer: e.target.value })
            }
          >
            <option default className="ui-option" value="none">
              Производители
            </option>
            {manufacturers.map((manufacturer) => (
              <option
                value={manufacturer.id}
                key={manufacturer.id}
                style={{ color: manufacturer.Color }}
              >
                {manufacturer.Name}
              </option>
            ))}
          </USelect>
          <UArea
            placeholder="Описание"
            type="text"
            value={newFluid.Description}
            onChange={(e) =>
              setNewFluid({ ...newFluid, Description: e.target.value })
            }
          />
          <div className="ui-input-fluid-line-full">
            <UInput
              placeholder="Стоимость"
              type="text"
              style={{ marginRight: "22px" }}
              value={newFluid.Price}
              onChange={(e) =>
                setNewFluid({ ...newFluid, Price: e.target.value })
              }
            />
            <UInput
              placeholder="Рейтинг"
              type="text"
              style={{ marginRight: "22px" }}
              value={newFluid.Rating}
              onChange={(e) =>
                setNewFluid({ ...newFluid, Rating: e.target.value })
              }
            />
            <USelect
              className="select-wrapper2"
              value={newFluid.Buying}
              onChange={(e) =>
                setNewFluid({ ...newFluid, Buying: e.target.value })
              }
            >
              <option default className="ui-option" value="none">
                Покупка
              </option>
              <option>-3</option>
              <option>-2</option>
              <option>-1</option>
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </USelect>
          </div>
          <div className="ui-button-plate">
            {isNewFluid ? (
              <span></span>
            ) : (
              <UButton onClick={deleteTab}>Удалить</UButton>
            )}
            <UButton onClick={workTab}>Сохранить</UButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FluidsModal;
