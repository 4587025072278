import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UName from "../UI/UName";
import UInputName from "../UI/UInputName";
import UInput from "../UI/UInput";
import UArea from "../UI/UArea";
import UButton from "../UI/UButton";
import UImage from "../UI/UImage";
import ApiService from "../../API/ApiService";
import UStars from "../UI/UStars";

const DevicesModal = ({ visible, setVisible, updateDevice }) => {
  const router = useNavigate();
  const params = useParams();
  const [isNewDevice, setIsNewDevice] = useState(true);
  const [stars, setStars] = useState(0);

  const [newDevice, setNewDevice] = useState({
    Name: "",
    Rating: "",
    Description: "",
    Image: "",
  });
  const [inputCode, setInputCode] = useState("");
  const [inputImage, setInputImage] = useState("");

  const fetching = async () => {
    if (params.id && params.id === "new") {
      setIsNewDevice(true);
      setStars(0);
    } else if (params.id) {
      const response = await ApiService.getDevicesId(params.id);
      setNewDevice({
        Name: response.data.Name,
        Rating: response.data.Rating,
        Description: response.data.Description,
        Image:
          "https://api.smoke-story.site/images/devices/" + response.data.Image,
      });
      setIsNewDevice(false);
      setInputImage("");
      setStars(Number(response.data.Rating));
    }
  };

  const deleteTab = async (e) => {
    e.preventDefault();
    if (!inputCode) {
      alert("Укажите код для работы запроса");
    } else {
      const response = await ApiService.deleteDevices(params.id, inputCode);
      if (!response) {
        alert(
          "Произошла ошибка при выполнении запроса, проверьте правильность кода"
        );
      } else {
        router(`/devices`);
        setVisible("none");
        updateDevice();
      }
    }
  };

  const workTab = async (e) => {
    e.preventDefault();
    if (newDevice.Name && stars > 0 && inputCode && newDevice.Description) {
      if (isNewDevice) {
        if (inputImage) {
          const bodyFormData = new FormData();
          bodyFormData.append("id", "");
          bodyFormData.append("Name", newDevice.Name);
          bodyFormData.append("Rating", stars);
          bodyFormData.append("Description", newDevice.Description);
          bodyFormData.append("Image", inputImage);
          const response = await ApiService.postDevices(
            bodyFormData,
            inputCode
          );
          if (!response) {
            alert(
              "Произошла ошибка при выполнении запроса, проверьте правильность кода"
            );
          } else {
            router(`/devices`);
            setVisible("none");
            updateDevice();
          }
        } else {
          alert("Добавьте изображение");
        }
      } else {
        const PatchBody = {
          Name: newDevice.Name,
          Rating: stars,
          Description: newDevice.Description,
          Image: inputImage,
        };
        const response = await ApiService.patchDevices(
          PatchBody,
          params.id,
          inputCode
        );
        if (!response) {
          alert(
            "Произошла ошибка при выполнении запроса, проверьте правильность кода"
          );
        } else {
          router(`/devices`);
          setVisible("none");
          updateDevice();
        }
      }
    } else {
      alert("Заполните все данные или проверьте правильность их заполнения");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = function () {
      setNewDevice({ ...newDevice, Image: reader.result });
      setInputImage(reader.result);
    };
  };

  useEffect(() => {
    setNewDevice({ Name: "", Rating: "", Description: "", Image: "" });
    fetching();
  }, [visible]);

  return (
    <div
      className="modalFog"
      style={{ display: visible }}
      onClick={() =>
        router(
          `/devices`,
          setVisible("none"),
          document.body.classList.remove("modal-open")
        )
      }
    >
      <div className="modal-plate" onClick={(e) => e.stopPropagation()}>
        <UName name="Девайс" />
        <form>
          <UInputName
            placeholder="Имя"
            type="text"
            value={newDevice.Name}
            onChange={(e) =>
              setNewDevice({ ...newDevice, Name: e.target.value })
            }
          />
          <div className="ui-flex-flavours">
            <div className="ui-flex-left-image">
              <UArea
                placeholder="Описание"
                style={{ width: "100%", height: "100%", marginBottom: "0" }}
                type="text"
                value={newDevice.Description}
                onChange={(e) =>
                  setNewDevice({ ...newDevice, Description: e.target.value })
                }
              />
            </div>
            <UImage
              value={newDevice.Image}
              onChange={handleChange}
              alt={newDevice.Name}
            />
          </div>
          <div className="ui-input-fluid-line" style={{ marginBottom: "22px" }}>
            <UInput
              placeholder="Код"
              type="password"
              value={inputCode}
              style={{ marginRight: "32px" }}
              onChange={(e) => setInputCode(e.target.value)}
            />
            <UStars stars={stars} setStars={setStars} />
          </div>
          <div className="ui-button-plate">
            {isNewDevice ? (
              <span></span>
            ) : (
              <UButton onClick={deleteTab}>Удалить</UButton>
            )}
            <UButton onClick={workTab}>Сохранить</UButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DevicesModal;
